<template>
  <monitoring-items
    :activeModules="activeModules"
    :mapItems="mapLexItems"
    :api="api"
    #default="{items}"
  >
    <legislation-list
      :items="items"
      :ownApi="false"
    />
  </monitoring-items>
</template>

<script>
import MonitoringItems from '../components/MonitoringItems';
import LegislationList from '../../Legislation/LegislationList';
import { mapLexItems } from '../../Legislation/mapLexItems';

export default {
  props: {
    activeModules: Array,
  },
  data() {
    return {
      api: {
        moduleId: 'pk',
        list: 'lex',
        modules: 'module_lex_header',
        favoriteHated: 'favorite_and_hated_lex_header',
        favoriteHatedResponse: 'favorite_and_hateful_leges_list',
        bookmarks: 'bookmarks/fetchLegislation',
      },
      mapLexItems,
    }
  },
  components: {
    MonitoringItems,
    LegislationList,
  },
  metaInfo() {
    return {
      title: 'Monitoring ustaw',
    };
  },
};
</script>
